export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'We are a group of volunteers coming together to cater for needs of the Muslims.',
    description: 'We wanted to create a space where all Muslims can pray in Newcastle Under Lyme. This idea grew into NULIC, a non-profit organisation and established registered charity. We have expanded our charitable work to Asia and Africa, through our volunteers.',
    buttonLabel: 'Get started',
    btnclick: 'mission',
    imgStart: false,
    img: require('../../images/haram.jpg'),
    // img: require('../../images/svg-1.svg').default,
    alt:'haram',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: 'mission',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Mission',
    headline: 'To promote the teachings and tenets of Islam.',
    description: ' We want to provide a place of worship as well as facilities for islamic education, in Newcastle Under Lyme and beyond, and follow the teachings of the Quran and the Sunnah of Prophet Muhammad ﷺ. Within the framework of Islamic teachings, we aspire to help and support the community.',
    buttonLabel: 'Learn More',
    btnclick: 'services',
    imgStart: true,
    img: require('../../images/nabawi.jpg'),
    alt:'nabawi',
    dark: false,
    primary: false,
    darkText: true,
};